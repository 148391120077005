import { FieldArray, FormikProvider, useFormik } from "formik";
import React, { useEffect } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { fetchUsers, getUsers } from "../../app/reducers/Users/userSlice";
import { useNavigate, useLocation } from "react-router-dom";
import { generateOptions } from "../../utils/Utils";
import {
  fetchGreyQualities,
  getGreyQualities,
} from "../../app/reducers/GreyQuality/greyQualitySlice";
import { useMemo } from "react";
import FormikInputDateGroup from "../../components/formik/FormikInputDateGroup";
import FormikTextareaGroup from "../../components/formik/FormikTextareaGroup";
import FormikAsyncSelect from "../../components/formik/FormikAsyncSelect";
import SecondaryButton from "../../components/infrastructure/Buttons/SecondaryButton";
import DangerButton from "../../components/infrastructure/Buttons/DangerButton";
import ProgressBar from "../../components/progressBar/ProgressBar";
import { useState } from "react";
import { authAxiosInstance } from "../../utils/axiosConfig";
import {
  fetchLocations,
  getLocation,
} from "../../app/reducers/Location/locationSlice";
import {
  fetchCatalogs,
  getCatalog,
} from "../../app/reducers/Catalog/catalogSlice";
import {
  fetchProducts,
  getProduct,
} from "../../app/reducers/Product/productSlice";
import moment from "moment";
import { toast } from "react-toastify";
import { createDirectPO } from "../../app/reducers/DirectPO/DirectPOSlice";
import QueryString from "qs";
import { createSale } from "../../app/reducers/Sale/saleSlice";
import ReceivedModal from "./ReceivedModal";
import { Minus, Plus } from "react-feather";

const Sale = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentItem, setCurrentItem] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const [progressLoading, setProgressLoading] = useState(false);
  const [showReceivedModal, setShowReceivedModal] = useState(false);
  const [saleData, setSaleData] = useState("");
  const histLocation = useLocation();

  const locationInfoId = localStorage.getItem("locationInfoId");

  useEffect(() => {
    dispatch(fetchUsers({ role: "manufacturer" }));
    dispatch(fetchGreyQualities());
    dispatch(fetchLocations());
  }, []);

  const { location } = useSelector(getLocation);

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: {
      location: locationInfoId ? locationInfoId : "",
      customerId: histLocation?.state?.customer?._id
        ? histLocation.state.customer?._id
        : "",
      customerName: histLocation?.state?.customer?.name
        ? histLocation.state.customer?.name
        : "",
      customerPhone: histLocation?.state?.customer?.username
        ? histLocation.state.customer?.username
        : "",
      product: [],
    },
    validationSchema: Yup.object({
      customerId: Yup.string().required(),
      customerName: Yup.string().required(),
      customerPhone: Yup.string().required(),
      product: Yup.array()
        .of(Yup.object({}))
        .required()
        .min(1, "Please Add atleast one product"),
    }),
    onSubmit: async (values) => {
      console.log(values.product);
      let filterProduct = values.product?.filter((d) => d.sku);
      if (filterProduct.length > 0) {
        setSaleData({ ...values, product: filterProduct });
        setShowReceivedModal(true);
      } else {
        alert("Please scan atleast one barcode");
      }
    },
  });

  const onKeyPressBarcode = async (event, i, arrayHelpers) => {
    event.stopPropagation();
    if (event.keyCode === 13 && event.target.value) {
      let checkExist = formik.values.product?.find(
        (d) => d.sku == event.target.value
      );
      const string = QueryString.stringify({
        sku: event.target.value,
        populate: true,
      });
      const resp = await authAxiosInstance.get(`product?${string}`);
      const product = resp.data.data.docs[0];
      if (!product) {
        return alert("Product Not found");
      }
      const stringInv = QueryString.stringify({
        product: product._id,
        location: formik.values.location,
      });
      const invRsp = await authAxiosInstance.get(
        `productInventory?${stringInv}`
      );
      const stock = invRsp.data.data.docs[0];
      if (stock?.finished <= 0) {
        return alert("Product out of stock");
      }
      if (!stock) {
        return alert("Stock not found");
      }
      if (checkExist) {
        let incQty = checkExist.qty + 1;
        console.log(stock?.finished, incQty, "new hu");
        if (stock?.finished < incQty) {
          return alert("Product out of stock");
        }
        let productIndex = formik.values.product?.findIndex(
          (d) => d.sku == event.target.value
        );
        formik.setFieldValue(`product.${productIndex}.qty`, incQty);
        formik.setFieldValue(`product.${i}.product_id`, "");
        formik.setFieldValue(`product.${i}.id`, "");
        return toast.success("Qty updated on product");
      }

      formik.setFieldValue(`product.${i}.sku`, product.sku);
      formik.setFieldValue(`product.${i}.price`, product.catalogData.price);
      formik.setFieldValue(`product.${i}.sellPrice`, product.catalogData.price);
      formik.setFieldValue(`product.${i}.product_id`, product._id);
      formik.setFieldValue(`product.${i}.location`, formik.values.location);
      formik.setFieldValue(`product.${i}.qty`, 1);
      formik.setFieldValue(`product.${i}.discount`, 0);
      formik.setFieldValue(`product.${i}.category_id`, product.category);
      formik.setFieldValue(`product.${i}.catalog_id`, product.catalog);
      formik.setFieldValue(
        `product.${i}.catalogName`,
        product.catalogData.name
      );
      formik.setFieldValue(`product.${i}.color`, product.color);
      arrayHelpers.push("");
    }
  };

  const increment = async (e, i) => {
    e.stopPropagation();

    let data = formik.values.product[i];
    console.log(data, "stock");
    const stringInv = QueryString.stringify({
      product: data.product_id,
      location: formik.values.location,
    });
    const invRsp = await authAxiosInstance.get(`productInventory?${stringInv}`);
    const stock = invRsp.data.data.docs[0];
    console.log(stock, "stock");
    if (stock?.finished <= 0) {
      return alert("Product out of stock");
    }
    if (!stock) {
      return alert("Stock not found");
    }
    let incQty = data.qty + 1;
    if (stock?.finished < incQty) {
      return alert("Product out of stock");
    }
    formik.setFieldValue(`product.${i}.qty`, incQty);
  };

  const decrement = async (e, i) => {
    e.stopPropagation();
    let data = formik.values.product[i];
    if (data.qty == 1) {
      window.alert("Not allowed");
    } else {
      let incQty = data.qty - 1;
      formik.setFieldValue(`product.${i}.qty`, incQty);
    }
  };

  return (
    <>
      <ReceivedModal
        setShowReceivedModal={setShowReceivedModal}
        showReceivedModal={showReceivedModal}
        saleData={saleData}
        saleFormik={formik}
      />
      <PageWithCard heading="Sale">
        <div className="text-slate-800 font-semibold mb-4">Sale</div>
        {/* {loading ? (
                <ClipLoader />
            ) : ( */}
        {progressLoading ? (
          <ProgressBar currentItem={currentItem} totalItem={totalItem} />
        ) : (
          <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
            <FormikAsyncSelect
              name="customerId"
              label="Customer"
              formik={formik}
              getOptions={async (value) => {
                const query = {
                  search: value,
                  role: "customer",
                  populate: true,
                };
                const string = QueryString.stringify(query);
                const users = await authAxiosInstance.get(`user?${string}`);
                const options = users.data.data.docs.map((ele) => ({
                  label: `${ele.name} / ${ele.username}`,
                  value: ele._id,
                }));
                return options;
              }}
              onChange={async (selectedOption) => {
                const string = QueryString.stringify({
                  _id: selectedOption.value,
                  populate: true,
                });
                const stringCredit = QueryString.stringify({
                  userId: selectedOption.value,
                  populate: true,
                });
                const resp = await authAxiosInstance.get(`user?${string}`);
                const userCredit = await authAxiosInstance.get(
                  `user/get/credit?${stringCredit}`
                );
                console.log(userCredit, "userCredit");
                console.log(resp, "ne hj");
                const user = resp.data.data.docs[0];
                const userBalance = userCredit.data.data.userCredit;
                formik.setFieldValue("customerId", user._id);
                formik.setFieldValue("customerName", user.name);
                formik.setFieldValue("customerPhone", user.username);
                formik.setFieldValue("customerCredit", userBalance);
              }}
              secondField={
                <SecondaryButton
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/addCustomer");
                  }}
                >
                  Add Customer
                </SecondaryButton>
              }
            />
            <div className="flex gap-4 w-full">
              <FormikInputGroup
                name="customerName"
                formik={formik}
                label="Customer Name"
                readOnly
                fullWidth
              />
              <FormikInputGroup
                name="customerPhone"
                formik={formik}
                label="Customer Phone"
                readOnly
                fullWidth
              />
              <FormikInputGroup
                name="customerCredit"
                formik={formik}
                label="Customer Credit"
                readOnly
                fullWidth
              />
            </div>
            <FormikSelectGroup
              formik={formik}
              label="Location"
              name="location"
              isDisabled
              // onChange={(selectedOption) => {
              //   formik.setFieldValue("product", [""]);
              //   formik.setFieldValue("location", selectedOption.value);
              // }}
              required
              options={generateOptions({
                array: location ? location.docs : [],
                valueField: "_id",
                labelField: "name",
              })}
            />
            {formik.values.location && (
              <>
                <FormikProvider value={formik}>
                  <FieldArray
                    name="product"
                    render={(arrayHelpers) => {
                      return (
                        <div className="flex flex-col gap-2">
                          {/* <p>Address</p> */}
                          <div>
                            {formik.values.product.map((ele, index) => (
                              <div
                                className="relative p-4 mb-2"
                                style={{
                                  border: "1px solid #d6c7c7",
                                  borderRadius: "5px",
                                }}
                                key={index}
                              >
                                {!formik.values.product[index].sku && (
                                  <FormikInputGroup
                                    autoFocus={true}
                                    formik={formik}
                                    label={`Barcode${index + 1}`}
                                    name={`product.${index}.id`}
                                    onKeyDown={(e) => {
                                      onKeyPressBarcode(e, index, arrayHelpers);
                                      // formik.setFieldValue(
                                      //   `product.${index}.id`,
                                      //   ""
                                      // );
                                    }}
                                  />
                                )}
                                {formik.values.product[index].sku && (
                                  <div className="flex gap-3">
                                    <FormikInputGroup
                                      formik={formik}
                                      label={`sku`}
                                      name={`product.${index}.sku`}
                                      readOnly
                                    />
                                    <FormikInputGroup
                                      formik={formik}
                                      label={`Catalog Name`}
                                      name={`product.${index}.catalogName`}
                                      readOnly
                                    />
                                    <FormikInputGroup
                                      formik={formik}
                                      label="Color"
                                      name={`product.${index}.color`}
                                      readOnly
                                    />
                                    <FormikInputGroup
                                      formik={formik}
                                      label={`Price`}
                                      name={`product.${index}.price`}
                                      readOnly
                                    />
                                    <FormikInputGroup
                                      formik={formik}
                                      label={`Discount(%)`}
                                      name={`product.${index}.discount`}
                                      readOnly
                                    />
                                    <Minus
                                      onClick={(e) => decrement(e, index)}
                                      style={{ marginTop: "25px" }}
                                    />
                                    <FormikInputGroup
                                      formik={formik}
                                      label={`Qty`}
                                      name={`product.${index}.qty`}
                                      readOnly
                                    />
                                    <Plus
                                      onClick={(e) => increment(e, index)}
                                      style={{ marginTop: "25px" }}
                                    />
                                    <FormikInputGroup
                                      formik={formik}
                                      label={`Selling Price`}
                                      type="number"
                                      max={formik.values.product[index].price}
                                      onChange={(e) => {
                                        console.log(
                                          e.target.value,
                                          formik.values.product[index].price
                                        );
                                        if (
                                          e.target.value >
                                          formik.values.product[index].price
                                        ) {
                                          alert("Limit Exceeded");
                                          return formik.setFieldValue(
                                            `product.${index}.sellPrice`,
                                            formik.values.product[index].price
                                          );
                                        }
                                        let difference =
                                          formik.values.product[index].price -
                                          e.target.value;
                                        let findPer =
                                          (difference /
                                            formik.values.product[index]
                                              .price) *
                                          100;
                                        formik.setFieldValue(
                                          `product.${index}.discount`,
                                          findPer
                                        );
                                        formik.setFieldValue(
                                          `product.${index}.sellPrice`,
                                          e.target.value
                                        );
                                      }}
                                      name={`product.${index}.sellPrice`}
                                    />
                                  </div>
                                )}
                                <div>
                                  <DangerButton
                                    className="mt-3"
                                    onClick={() => {
                                      arrayHelpers.remove(index);
                                    }}
                                    type="button"
                                  >
                                    Remove
                                  </DangerButton>
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="flex gap-3">
                            <SecondaryButton
                              onClick={() => {
                                arrayHelpers.push("");
                              }}
                              type="button"
                            >
                              Add More
                            </SecondaryButton>
                            {/* <div className="flex justify-center align-items-center"> */}
                            {formik.values.product &&
                              formik.values.product.length > 0 && (
                                <div className="mt-2">
                                  <h1>
                                    Total Quantity:-{" "}
                                    <span className="font-bold">
                                      {formik.values.product?.reduce(
                                        (acc, ele) => acc + (ele.qty || 0),
                                        0
                                      )}
                                    </span>
                                  </h1>
                                </div>
                              )}
                            {/* </div> */}
                          </div>
                        </div>
                      );
                    }}
                  />
                  {formik.errors["product"] &&
                  !Array.isArray(formik.errors["product"]) &&
                  formik.errors["product"] ? (
                    <p className="text-xs text-red-500">
                      {formik.errors["product"]}
                    </p>
                  ) : null}
                </FormikProvider>
              </>
            )}
            <div>
              <PrimaryButton type="button" onClick={formik.handleSubmit}>
                Submit
              </PrimaryButton>
            </div>
          </form>
        )}
      </PageWithCard>
    </>
  );
};

export default Sale;
